import NotificationConstants from './NotificationConstants';

const initialState = {
  notifications: [],
};

export default function NotificationsReducer(state = initialState, action) {
  switch (action.type) {
    case NotificationConstants.NOTIFICATION_ADDED: {
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
      };
    }

    case NotificationConstants.NOTIFICATION_CLOSED: {
      return {
        ...state,
        notifications: state.notifications.filter(n => n.id !== action.notificationId),
      };
    }

    default:
      return state;
  }
}
