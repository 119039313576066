import * as Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  update(organizationId, params) {
    const url = Routes.api_v2_organization_path(organizationId);

    const formData = FetchHelpers.toFormData('organization', params);
    return FetchHelpers.patchMultipartFormdata(url, formData);
  },

  index(query) {
    const url = Routes.api_v2_organizations_path(query);
    return FetchHelpers.get(url);
  },

  show(organizationId) {
    const url = Routes.api_v2_organization_path(organizationId);
    return FetchHelpers.get(url);
  },
};
